import {
    appConfig,
    pad
} from "../../../utils.js";

import add from "date-fns/add";
import {
    get
} from "lodash";

export const addNonCon = ({ form, pageItem, store }) => {

    const proceduresToPick = Object.keys(pageItem.Procedures || {}).map(key => {
        let p = store.state["Audits/Procedures"][key];
        return {
            id: key,
            label: p.Title,
            meta: p
        }
    });

    form.setConfig({
        "title": "Add NonCon",
        "type": "push",
        "location": "Audits/NonConformities",
        "fields": [{
            "label": "Number",
            "type": "value",
            value() {
                return `${appConfig.docPrefix}NC-${pageItem.Number.split('-')[1]}-${pad(Object.keys(pageItem.NonConformities || {}).length + 1, 2)}`
            }
        }, {
            "label": "Procedure",
            "name": "ProcedureID",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dropdownData": proceduresToPick,
        }, {
            "label": "Severity",
            "name": "Severity",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityLevels",
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityCategories",
        }],
        preSubmitHook({ liveInputData, dropdownData }) {
            let selected = dropdownData.Severity.find(i => i.id === liveInputData.Severity);
            let procedure = dropdownData.ProcedureID.find(i => i.id === liveInputData.ProcedureID);

            return {
                "Comment": "",
                "Completed": false,
                "CorrectiveAction": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false
                },
                "Cycle": pageItem.Cycle,
                "Date": {
                    "Created": (new Date()).toISOString(),
                    "Completed": "N/A",
                    "Due": add(new Date(), { days: selected.meta.Days }).toISOString(),
                    "HOD": "N/A",
                    "Initial": add(new Date(), { days: selected.meta.Days }).toISOString()
                },
                "Extension": {
                    "Granted": false,
                    "Requested": false
                },
                "Issue": {
                    "Comment": "",
                    "HOD": false
                },
                "Number": `${appConfig.docPrefix}NC-${pageItem.Number.split('-')[1]}-${pad(Object.keys(pageItem.NonConformities || {}).length + 1, 2)}`,
                "Overdue": false,
                "PreventativeAction": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false,
                },
                "Procedure": procedure.label,
                "Parent": pageItem.Number,
                "ParentID": pageItem.ID,
                "Report": pageItem.Number,
                "ReportID": pageItem.ID,
                "RootCause": {
                    "Comment": "",
                    "DAM": false,
                    "HOD": false
                }
            }
        },
        async postSubmitHook({ db, data, typeRef }) {
            await db.set(`Audits/Procedures/${data.ProcedureID}/NonConformities/${typeRef.key}`, true);
            await db.set(`Audits/${pageItem.ParentType}/${pageItem.ParentID}/NonConformities/${typeRef.key}`, true);
            await db.set(`Audits/Reports/${pageItem.ID}/NonConformities/${typeRef.key}`, true);
            await addGreenCard({ store, db, pageItem, data, 'NonConID': typeRef.key });
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editNonConSeverityAndType = ({ form, item }) => {
    form.setValues(item);

    form.setConfig({
        "title": "Edit Non Conformity",
        "type": "update",
        "location": `Audits/NonConformities/${item.ID}`,
        "fields": [{
            "label": "Severity",
            "name": "Severity",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityLevels",
        }, {
            "label": "Type",
            "name": "Type",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "labelKey": "Name",
            "idKey": "Name",
            "dataLocation": "Audits/Settings/NonConformityCategories",
        }],
        preSubmitHook({ liveInputData, dropdownData }) {
            let selected = dropdownData.Severity.find(i => i.id === liveInputData.Severity);

            return {
                "Date": {
                    "Due": add(new Date(item.Date.Created), { days: selected.meta.Days }).toISOString()
                }
            };
        }
    });

    form.getDropdownData();
    form.toggle();
};

export const adjustNonConDueDate = ({ form, item }) => {

    form.setValue("Date[Due]", item.Date.Due);

    form.setConfig({
        "title": "Adjust Non Conformity Due Date",
        "type": "update",
        "location": `Audits/NonConformities/${item.ID}`,
        "fields": [{
            "label": "Due Date",
            "name": "Date[Due]",
            "type": "date",
            "required": true
        }],
        preSubmitHook() {
            return {
                "Overdue": (new Date()).getTime() > (new Date(item.Date.Due)).getTime()
            };
        }
    });
    form.toggle();
};

export const editIssueType = path => ({ form, pageItem }) => {

    const splitPath = path.split(".");
    const name = path.replace(".", "/");

    form.setValues({
        [name]: get(pageItem, path)
    });

    form.setConfig({
        "title": `Edit ${splitPath[0]}`,
        "type": "update",
        "location": `Audits/NonConformities/${pageItem.ID}`,
        "fields": [{
            "label": splitPath[0],
            "type": "richtext",
            "divClass": "col-span-3",
            "name": name
        }]
    });

    form.toggle();
};

export const addGreenCard = async ({ store, db, pageItem, data, NonConID }, parentLocation = "Audits/NonConformities/") => {
    let jobCard = await db.push("Database/JobCards", {
        "Number": `C.${pad(store.state.counts.cards.count, 3)}`,
        "Cancelled": false,
        "Commercial": "00000",
        "CommercialID": "--new00000",
        "Date": {
            "Cancelled": "N/A",
            "Created": new Date().toISOString(),
            "Signedoff": {
                "Auditor": "Not Checked",
                "Engineer": "Not Checked",
                "TechincalSecretary": "Not Checked"
            }
        },
        "Parent": pageItem.Number,
        "ParentID": pageItem.ID,
        "ResponsibleEngineer": "Needs Assigning",
        "ParentLocation": parentLocation || "Audits/NonConformities/",
        "Title": `${pageItem.Number} - ${data.Type}`,
        "Signedoff": {
            "Auditor": false,
            "Engineer": false,
            "TechincalSecretary": false
        },
        "Stage": "Awaiting Technical Secretary Check",
        "Type": "Green Card",
    });
    await db.set(`${parentLocation}${NonConID}/JobCards/${jobCard.key}`, true);
    await db.set(`counts/cards/count`, store.state.counts.cards.count + 1);
};

export const accept = (who, type) => ({ form, pageItem }) => {
    form.setConfig({
        "title": `${who}: Accept ${type}`,
        "type": "update",
        "location": `Audits/NonConformities/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                [`${type}/${who}`]: true,
                [`Date/${type}/${who}`]: (new Date()).toISOString()
            };
        },
        async postSubmitHook({ db }) {
            let data = await db.once(`Audits/NonConformities/${pageItem.ID}`);

            if (
                data.CorrectiveAction.HOD &&
                data.CorrectiveAction.DAM &&
                data.RootCause.HOD &&
                data.RootCause.DAM &&
                data.PreventativeAction.HOD &&
                data.PreventativeAction.DAM &&
                !data.Completed
            ) {
                db.update(`Audits/NonConformities/${pageItem.ID}`, {
                    "Completed": true,
                    "Date/Completed": (new Date()).toISOString()
                });
                for (let jobCard of Object.keys(pageItem.JobCards || {})) {

                    await db.update(`Database/JobCards/${jobCard}`, {
                        "Signedoff": {
                            "Engineer": true,
                        },
                        "Date": {
                            "Signedoff": {
                                "Engineer": (new Date()).toISOString()
                            }
                        }
                    })
                }
            }
        }
    });
    form.toggle();
}

export const requestExtension = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Request a Non Conformity Due Date Extension",
        "type": "update",
        "location": `Audits/NonConformities/${pageItem.ID}`,
        "fields": [{
            "label": "Reason",
            "name": "Comment"
        }],
        preSubmitHook() {
            return {
                "Extension/Granted": false,
                "Extension/Requested": true,
                "Date/Entension/Requested": (new Date()).toISOString(),
                "Overdue": (new Date()).getTime() > (new Date(pageItem.Date.Due)).getTime()
            }
        }
    });
    form.toggle();
};

export const grantExtension = ({ form, pageItem }) => {
    form.setConfig({
        "title": "Grant Non Conformity Due Date Extension",
        "type": "update",
        "location": `Audits/NonConformities/${pageItem.ID}`,
        "fields": [],
        preSubmitHook() {
            return {
                "Extension/Granted": true,
                "Date/Entension/Granted": (new Date()).toISOString(),
                "Overdue": (new Date()).getTime() > (new Date(pageItem.Date.Due)).getTime()
            }
        }
    });
    form.toggle();
};

export const changeProcedure = async ({ form, pageItem, db, store }) => {

    const parent = await db.once(`Audits/Reports//${pageItem.ReportID}`);
    const procedureIDs = Object.keys(parent.Procedures || {});

    const proceduresToPick = procedureIDs.map(key => {
        let p = store.state["Audits/Procedures"][key];
        return {
            id: key,
            label: p.Title,
            meta: p
        }
    });

    const currentProcedureID = pageItem.ProcedureID;

    form.setConfig({
        "title": "Change Procedure",
        "type": "update",
        "location": `Audits/NonConformities/${pageItem.ID}`,
        "fields": [{
            "label": "Procedure",
            "name": "ProcedureID",
            "type": "dropdown",
            "multiple": false,
            "required": true,
            "dropdownData": proceduresToPick,
        }],
        preSubmitHook({ liveInputData, dropdownData }) {
            let procedure = dropdownData.ProcedureID.find(i => i.id === liveInputData.ProcedureID);

            return {
                "Procedure": procedure.label
            };
        },
        async postSubmitHook({ newData }) {
            await db.set(`Audits/Procedures/${currentProcedureID}/NonConformities/${pageItem.ID}`, null);
            await db.set(`Audits/Procedures/${newData.ProcedureID}/NonConformities/${pageItem.ID}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
}
