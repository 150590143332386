import {
    addSTC,
    editSTC,
    addStcValidation,
    editStcValidation
} from "./actions";

export const stcs = {
    "label": "STC's",
    "key": "STCs",
    "location": "Database/STCs/",
    "actions": [{
        "label": "Add STC",
        "action": addSTC
    }],
    "rowActions": [{
        "label": "Edit STC",
        "action": editSTC
    }],
    "headers": [{
        "headerName": "Application Number",
        "field": "ApplicationNumber"
    }, {
        "headerName": "STC Approval Number",
        "field": "Number"
    }, {
        "headerName": "Date of Application",
        "field": "Date.Application",
        "type": "date"
    }, {
        "headerName": "Date Issued",
        "field": "Date.Issued",
        "type": "date"
    }, {
        "headerName": "Project Certification Manager",
        "field": "ProjectCertificationManager"
    }]
}

export const stcVals = {
    "label": "STC Validations",
    "key": "Validations",
    "location": "Database/Validations/",
    "actions": [{
        "label": "Add Validation",
        "action": addStcValidation,
        show({ pageItem }) {
            return [!!pageItem.STCs, "Must have an STC"]
        }
    }],
    "rowActions": [{
        "label": "Edit Validation",
        "action": editStcValidation
    }],
    "headers": [{
        "headerName": "Certifying Authority (CA)",
        "field": "CA"
    }, {
        "headerName": "CA Project Number",
        "field": "CAProjectNumber"
    }, {
        "headerName": "CA Contact",
        "field": "EASAContact"
    }, {
        "headerName": "CA STC",
        "field": "CASTC"
    }, {
        "headerName": "Validating Authority (VA)",
        "field": "VA"
    }, {
        "headerName": "VA Project Number",
        "field": "VAProjectNumber"
    }, {
        "headerName": "VA STC",
        "field": "VASTC"
    }, {
        "headerName": "VA Contact",
        "field": "ValidationContact"
    }]
}
