import {
    downloadPdf
} from "../../../pdfDownloader.js";
import { get } from "lodash";

// Configuration object mapping each key to its headers and fields
const keys = {
    Documents: {
        headers: ["Number", "Title", "Revision"],
        fields: [
            'Number',
            'Title',
            (item, pageItem) => get(item, `Revisions.${item.Links[pageItem.ID]}.Revision`, item.Revision)
        ]
    },
    Drawings: {
        headers: ["Number", "Title", "Revision"],
        fields: [
            'Number',
            'Title',
            (item, pageItem) => get(item, `Revisions.${item.Links[pageItem.ID]}.Revision`, item.Revision)
        ]
    },
    SADDs: {
        headers: ["Number", "POA Number", "Date of Issue"],
        fields: [
            'Number',
            'To',
            item => (new Date(item.Date.Issued).toLocaleDateString("en-gb"))
        ]
    },
    Effectivities: {
        headers: ["Number"],
        fields: ['Number']
    },
    Concessions: {
        headers: ["Number", "Comment"],
        fields: ['Number', 'Comment']
    },
    DQNs: {
        headers: ["Number", "Comment"],
        fields: ['Number', 'Comment']
    },
    ServiceBulletins: {
        headers: ["Number", "Comment"],
        fields: ['Number', 'Comment']
    },
    DOIs: {
        headers: ["Number", "Comment"],
        fields: ['Number', 'Comment']
    },
    JobCards: {
        headers: ["Number", "Title", "Colour"],
        fields: ['Number', 'Title', 'Type']
    },
    Miscellaneous: {
        headers: ["Type", "Number", "Title", "Revision"],
        fields: ["Type", 'Number', 'Title', "Revision"]
    }
};

// Generic function to create table body
function createBody(items, pageItem, config) {
    let body = [config.headers];
    items.forEach(item => {
        let row = config.fields.map(field => {
            if (typeof field === 'function') {
                return field(item, pageItem);
            } else {
                return get(item, field);
            }
        });
        body.push(row);
    });
    return body;
}

export const designChangeItemsReport = ({ pageItem, attachedItems, store }) => {
    let docDef = {
        "content": []
    };
    let con = docDef.content;

    // Design Change Report Title
    con.push({
        "text": `S21.${pageItem.Number}  - ${pageItem.Title}`,
        "fontSize": 12
    });

    // Add some basic information about the Design Change
    con.push({
        "columns": [{
            "width": "25%",
            "text": "Parent",
            "bold": true
        }, {
            "width": "25%",
            "text": "Customer",
            "bold": true
        }, {
            "width": "25%",
            "text": "Responsible Engineer",
            "bold": true
        }, {
            "width": "25%",
            "text": "Cert. Number",
            "bold": true
        }],
        "margin": [0, 12]
    });
    con.push({
        "columns": [{
            "width": "25%",
            "text": pageItem.Parent
        }, {
            "width": "25%",
            "text": pageItem.Customer
        }, {
            "width": "25%",
            "text": pageItem.ResponsibleEngineer
        }, {
            "width": "25%",
            "text": pageItem.EASANumber
        }]
    });

    // Add all the tables
    for (let [key, value] of Object.entries(attachedItems)) {
        if (value.items.length < 1) continue;
        if (!keys[key]) continue;

        let items = value.items.map(i => store.state[i]).filter(i => !!i).filter(i => !i.Cancelled);

        let body = createBody(items, pageItem, keys[key]);

        con.push({
            "text": key,
            "fontSize": 12,
            "margin": [0, 12, 0, 12]
        });

        con.push({
            "table": {
                "headerRows": 1,
                "body": body,
            }
        });
    };

    downloadPdf(docDef)
};
