import Settings from "./Settings.vue";
import categories from "./categories.js";

export default [{
    "path": "/SafetyManagement/Settings",
    "component": Settings,
    "name": "Safety Reporting Settings",
    "meta": {
        "app": "SafetyManagement",
        "name": "Safety Reporting  Settings",
        "sideNav": true,
        "sideNavSection": "Settings",
        title() {
            return "Safety Reporting Settings"
        },
        "dataFields": [],
        "menuActions": [],
        "attachedItems": [categories]
    }
}];
