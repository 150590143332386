import Audits from "./Audits/routes.js";
import Claims from "./Claims/routes.js";
import Database from "./Database/routes.js";
import FlightTestCrew from "./FlightTestCrew/routes.js";
import Home from "./Home/routes.js";
import Leave from "./Leave/routes.js";
import Matrices from "./Matrices/routes.js";
import OpenForms from "./OpenForms/routes.js";
import SafetyManagement from "./SafetyManagement/routes.js";

const all = [
    ...Home,
    ...Database,
    ...Audits,
    ...Leave,
    ...Matrices,
    ...FlightTestCrew,
    ...OpenForms,
    ...Claims,
    ...SafetyManagement
];

const allActions = all.map(route => {
    if (route.path.toLowerCase().includes('cards')) {
        console.log(route);
        const allowedFunctionKeys = ['show', 'filter', 'lock'];
        console.log(JSON.stringify(route, function (key, val) {
            if (typeof val === 'function' && allowedFunctionKeys.includes(key)) {
                return val + ''; // implicitly `toString` it
            }
            return val;
        }));
    }
    if(route?.meta?.menuActions && route?.meta?.menuActions.length > 0) {
        return route.meta.menuActions.map(action => {
            const actionfn = action.action.toString();
            

            // Extract the function signature (up to the first `{`)
            const signature = actionfn.match(/^[^(]*\(([^)]*)\)/);

            // Safely get the arguments
            const args = signature && signature[1]
                .split(',')                  // Split by commas
                .map(arg => arg.trim())      // Trim whitespace
                .filter(arg => arg)          // Remove empty strings
                .map(arg => arg.replace(/[\{\}\[\]]/g, '').trim()); // Remove brackets and extra spaces

            return {
                ...action,
                route: route.path,
                args
            };
        });
    };
}).flat().filter(action => !!action);

console.log(allActions);


export default all;
