import {
} from "../../../utils.js";

export const addSTC = ({ form, pageItem, meta }) => {
    form.setConfig({
        "title": "Add STC",
        "type": "push",
        "location": "Database/STCs",
        "fields": [{
            "label": "STC Application Date",
            "name": "Date[Application]",
            "type": "date"
        }],
        preSubmitHook() {
            return {
                "Number": "Input on Issue",
                "ApplicationNumber": "Input on issue",
                "ParentID": pageItem.ID,
                "ParentType": meta.ParentType,
                "Parent": pageItem.Number
            }
        },
        async postSubmitHook({ db, typeRef }) {
            await db.set(`Database/${meta.ParentType}/${pageItem.ID}/STCs/${typeRef.key}`, true);
            await db.set(`Database/STCs/${typeRef.key}/${meta.ParentType}/${pageItem.ID}`, true);
        }
    });
    form.toggle();
};

export const editSTC = ({ form, item, meta }) => {
    form.setValues({
        "ApplicationNumber": item.ApplicationNumber,
        "Number": item.Number,
        "Date[Issued]": item.Date.Issued,
        "ProjectCertificationManager": item.ProjectCertificationManager
    })
    form.setConfig({
        "title": "Edit STC",
        "type": "update",
        "location": `Database/STCs/${item.ID}`,
        "fields": [{
            "label": "ApplicationNumber",
            "name": "ApplicationNumber",
            "type": "text"
        }, {
            "label": "STC Approval Number",
            "name": "Number",
            "type": "text"
        }, {
            "label": "Date Issued",
            "name": "Date[Issued]",
            "type": "date",
            "required": false
        }, {
            "label": "Project Certification Manager",
            "name": "ProjectCertificationManager",
            "type": "text",
            "required": true
        }],
        postSubmitHook({ db, data }) {
            db.set(`Database/${meta.ParentType}/${item.ParentID}/EASANumber`, data.Number);
        }
    });
    form.toggle();
};

export const addStcValidation = ({ form, pageItem, attachedItems, store, db }) => {
    
    form.setConfig({
        "title": "Add STC Validation",
        "type": "push",
        "location": "Database/Validations",
        "fields": [{
            "label": "Certifying Authority (CA)",
            "name": "CA",
            "type": "text"
        },{
            "label": "CA Project Number",
            "name": "CAProjectNumber",
            "type": "text"
        }, {
            "label": "CA Contact",
            "name": "EASAContact",
            "type": "text"
        }, {
            "break": true,
            "divClass": "col-span-3"
        }, {
            "label": "Validating Authority (VA)",
            "name": "VA",
            "type": "text"
        }, {
            "label": "VA STC Number",
            "name": "VASTC",
            "type": "text"
        }, {
            "label": "VA Project Number",
            "name": "VAProjectNumber",
            "type": "text"
        }, {
            "label": "VA Contact",
            "name": "ValidationContact",
            "type": "text"
        }, {
            "label": "Validation Date",
            "name": "Date",
            "type": "date"
        }],
        preSubmitHook() {
            return {
                "DesignChangeID": pageItem.ID,
                "DesignChange": pageItem.Number
            };
        },
        postSubmitHook({ typeRef }) {
            db.set(`Database/DesignChanges/${pageItem.ID}/Validations/${typeRef.key}`, true);
        }
    });
    form.getDropdownData();
    form.toggle();
};

export const editStcValidation = ({ form, item, attachedItems, store }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Add STC Validation",
        "type": "update",
        "location": `Database/Validations/${item.ID}`,
        "fields": [{
            "label": "Certifying Authority (CA)",
            "name": "CA",
            "type": "text"
        }, {
            "label": "CA STC Number",
            "name": "CASTC",
            "type": "dropdown",
            "type": "text"
        }, {
            "label": "CA Project Number",
            "name": "CAProjectNumber",
            "type": "text"
        }, {
            "label": "CA Contact",
            "name": "EASAContact",
            "type": "text"
        }, {
            "break": true,
            "divClass": "col-span-3"
        }, {
            "label": "Validating Authority (VA)",
            "name": "VA",
            "type": "text"
            }, {
                "label": "VA STC Number",
                "name": "VASTC",
                "type": "text"
            }, {
            "label": "VA Project Number",
            "name": "VAProjectNumber",
            "type": "text"
        }, {
            "label": "VA Contact",
            "name": "ValidationContact",
            "type": "text"
        }, {
            "label": "Validation Date",
            "name": "Date",
            "type": "date"
        }]
    });
    form.getDropdownData();
    form.toggle();
};  
