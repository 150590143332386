export default class CrewMember {
  constructor(data) {
    this.$d = data;
  }

  get raw() {
    return this.$d;
  }

  get Role() {
    return this.$d.Role;
  }

  get Name() {
    return this.$d.User?.FullName;
  }

  get Email() {
    return this.$d.User?.Email;
  }

  get DOB() {
    return this.$d.User?.DateOfBirth;
  }

  get Completed() {
    return this.$d.Completed;
  }

  get Approved() {
    return this.$d.Approved;
  }

  get Accepted() {
    return this.$d.Accepted;
  }

  get ApprovedBy() {
    return this.$d.ApprovedBy;
  }

  get AcceptedBy() {
    return this.$d.AcceptedBy;
  }

  get ID() {
    return this.$d.ID;
  }

  get RoleExperience() {
    return this.$d.RoleExperience || "Refer to Electronic Files Below";
  }

  get Limitations() {
    return this.$d.Limitations || "None";
  }

  get Date() {
    return this.$d.Date;
  }

  get Files() {
    return this.$d.Files ? Object.values(this.$d.Files) : [];
  }

  get FileNames() {
    return this.$d.Files ? Object.values(this.$d.Files) : [];
  }

  get NextExpiry() {
    return this.$d.Approved ? this.Date.Approved.split("-").map(i => i.length === 4 ? (parseInt(i) + 1).toString() : i).join("-") : "Not Approved";
  }

  get NextExpiryReason() {
    return this.$d.Approved ? "HoD Approval" : "Not Approved";
  }

  get EmergencyContact() {
    return this.$d.EmergencyContact;
  }

  get CRM() {
    return {
      "Completed": this.$d.CRM ? this.$d.CRM.Completed === "Yes" : false,
      "Date": this.$d.CRM && this.$d.CRM.Date ? this.$d.CRM.Date : "Not Completed"
    };
  }

  get FTA() {
    return this.$d.FTA || this.$d.TEMP_FTA || "Not Applicable";
  }

  get CCR() {
    return this.$d.CCR || this.$d.TEMP_CCR || "Not Applicable";
  }

  get PendingChanges() {
    return this.$d.HasOpened === false;
  }

  get DatabaseLink(){
    return {
      "url": `https://app.stc21.co.uk/FlightTest/ApprovedCrew/${this.ID}`,
      "text": `View ${this.Name} on the STC Twenty One Database App`
    }
  }
};
