const appConfig = JSON.parse(import.meta.env.VITE_APP_CONFIG);

import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-toastification/dist/index.css";
// import styles
import "./assets/styles.css";

import Toast, { useToast } from "vue-toastification";
// router
import {
    createRouter,
    createWebHistory
} from "vue-router";

import App from './App.vue'
// icons
import FontAwesomeIcon from "./icons.js";
// import components
import Treeselect from "vue3-treeselect/dist/vue3-treeselect.common.js";
// import v-tooltip
import VTooltipPlugin from 'v-tooltip'
import {
    createApp
} from 'vue'
import {
    db
} from "./db";
// dynamically import routes from the defined config
import routes from "./views/routes";
// store / vuex
import store from "./store.js";

const router = createRouter({
    "history": createWebHistory(),
    "routes": routes
});

const toast = useToast();

const w = t => {
    return new Promise((res, rej) => setTimeout(res, t || 1000));
}

let attempt = 0;
const max_attempts = 10;

// This is an asynchronous function called routeGuard which takes in two parameters, to and from, and returns either true or false.
async function routeGuard(to, from) {
    // If the meta data of the "to" route has an "open" property set to true, then return
    if (to.meta.open === true) return;

    if(attempt > max_attempts) {
        store.commit("save", {
            "failedUser": true
        });
        return;
    }

    // If the "store" object does not have a defined "state" property, or if the "state" property does not have a defined "user" property, or if the "user" property does not have a defined "Security" property, then wait for 3 seconds.
    if (!store?.state?.user?.Security) {
        const sleep = (milliseconds) =>
            new Promise((resolve) => setTimeout(resolve, milliseconds));
        await sleep(200);
        attempt++;
        return routeGuard(to, from);
    }

    if(!store.state.user.Security.General.User) {
        return false;
    }

    // If the "to" route has a defined "lock" meta property, then call the lock function with the parameters: store.state.user, { to, from, store, router, db }
    if (to?.meta?.lock) {
        const enter = await to?.meta?.lock(store.state.user, { to, from, store, router, db });

        // If the first element of the "enter" array is equal to true, then show an error message in a toast and return false
        if (enter[0] === true) {
            toast.error(
                "Navigation blocked, you may not have the permissions to view this page."
            );
            return false;
        };
    }

    // If the "to" route has a defined "storeData" meta property, then loop through each element of the "storeData" array and call the "store" method on the "db" object with the parameters: dataToStore and store.
    if (to?.meta?.storeData) {
        for (let dataToStore of to.meta.storeData) {

            
            await db.store(dataToStore, store);
        }
    }

    // Return true if all conditions have passed
    return true;
}

router.beforeEach(routeGuard);


const app = createApp(App)
app.use(VTooltipPlugin);
app.use(router);
app.use(store);
app.use(Toast, {
    "transition": "Vue-Toastification__fade",
    "maxToasts": 20,
    "newestOnTop": false,
    "position": "bottom-right",
    "timeout": 3000,
    "closeOnClick": true,
    "pauseOnFocusLoss": true,
    "pauseOnHover": true,
    "draggable": false,
    "draggablePercent": 0.6,
    "showCloseButtonOnHover": true,
    "hideProgressBar": true,
    "closeButton": false,
    "icon": true,
    "rtl": false
});
app
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("treeselect", Treeselect)
    .mount('#app')

