import fields from "./fields";
import { generateDocument } from "../Documents/actions";

const isEAF = ({ liveInputData }) => {
    return ["EAF"].includes(liveInputData.Type);
};


export function editEAFData({ item, db, form }) {
    form.setValues({
        ...item,
        "Datasheet": null
    });
    form.setConfig({
        "title": "Revise EAF",
        "location": `Database/Documents/${item.ID}/`,
        "type": "update",
        "isSteppedForm": true,
        "fields": fields,
        async onStepChange(x) {
            console.log(x);
        },
        async preSubmitHook({ liveInputData, dropdownData }) {

            let pn = dropdownData.PartNumberID.find(i => i.id === liveInputData.PartNumberID);

            await db.update(`Database/PartNumbers/${pn.id}`, {
                "Documents": {
                    [item.ID]: true
                }
            });

            return {
                "PartNumber": pn.label,
                "ManufacturerID": pn.meta.ManufacturerID,
                "PartNumbers": {
                    [liveInputData.PartNumberID]: true
                }
            }
        }
    });
    form.getDropdownData();
    form.toggle();
}

export const completeEAF = ({ item, db, form }) => {
    form.setValues(item);
    form.setConfig({
        "title": "Complete EAF",
        "location": `Database/Documents/${item.ID}/`,
        "description": "Are you sure you want to complete this EAF?",
        "type": "update",
        "fields": [],
        async preSubmitHook({ files }) {
            return {
                "Completed": true,
                "Date": {
                    "Completed": new Date().toISOString()
                }
            }
        }
    });
    form.toggle();
};

export const populateForm = async ({ item, db }) => {
    await generateDocument({ item, db });
}
