<template>
  <div>
    <div class="px-2 flex flex-row items-center bg-white">
      <p class="text-primary text-sm font-medium flex-grow" v-html="state.showCancelled
        ? `Cancelled ${tableConfig.label}`
        : tableConfig.label
        "></p>
      <IconMenu :actions="[
        ...computedActions,
        {
          label: 'Toggle Cancelled Items',
          action: toggleShowCancelled,
          break: true,
        },
        download,
      ]" :contextData="{ ...contextData, item: pageItem, items: computedItems }"
        v-if="!currentRoute.meta.preventAttachedItemsActions" />
    </div>
    <Fields title="" :contextData="{ ...contextData, item: pageItem, items: computedItems }"
      :dataFields="tableConfig.fields" :menuActions="[]" v-if="tableConfig.fields" :hideBackButton="true" />
    <DataTable ref="table" v-if="!tableConfig.field" :columns="tableConfig.headers"
      :tableSettings="{ columnState: computedTableConfigHeaders, domLayout: 'autoHeight' }"
      :rowClassRules="tableConfig.rowClassRules || {}" :cellClassRules="tableConfig.cellClassRules || {}"
      :items="computedItems" @itemSelected="(e) => attachedItemSelected(e, tableConfig)"
      :paginationPageSize="tableConfig.paginationPageSize" />
  </div>
  <ContextMenu ref="contextMenu" />
</template>
<script setup>
import { computed, defineProps, onMounted, reactive, ref, watch } from "vue";
import { Value, DataTable, IconMenu, ContextMenu } from "@comp/index.js";
import { useStore } from "vuex";
import { db } from "../../db.js";
import { useRoute, useRouter } from "vue-router";
import Fields from "../Fields/Fields.vue";
import { get, isObject, toArray } from "lodash";

const route = useRoute();
const router = useRouter();
const currentRoute = computed(() => route);

const store = useStore();

const table = ref(null);
const contextMenu = ref(null);

const props = defineProps({
  tableConfig: Object,
  pageItem: Object,
  contextData: Object,
});

const state = reactive({
  showCancelled: false,
});

const computedItems = ref([]);
function computeItems() {
  let _items;

  if (props.tableConfig.computedData) {
    _items = props.tableConfig.computedData(props.contextData);
  } else if (props.tableConfig.local === true) {
    _items = toArray(get(props.pageItem, props.tableConfig.key, {})).filter((item) => {
      return props.tableConfig.dataFilter ? props.tableConfig.dataFilter(item) : true;
    });
  } else {
    _items = props.tableConfig.items.map((key) => {
      return {
        __location: key,
        ...store.state[key],
      };
    }).filter((item) => {
      return (
        !!item &&
        Object.keys(item).length > 1 &&
        (item.Cancelled === undefined ? true : item.Cancelled === state.showCancelled) &&
        (props.tableConfig.dataFilter ? props.tableConfig.dataFilter(item) : true)
      );
    });
  }

  computedItems.value = isObject(_items) ? Object.values(_items) : _items;
}

watch(() => props.pageItem, () => {
  computedItems.value = computeItems();
  console.log("pageItem changed", computedItems.value);
});

const computedActions = computed(() => {
  let actions = props.tableConfig.actions || [];
  if (props.tableConfig.computedActions) actions = [
    ...actions,
    ...(props.tableConfig.computedActions(props.contextData))
  ];
  return actions
})

const download = computed(() => {
  return {
    label: "Download CSV",
    action: table.value?.download,
  };
});

const toggleShowCancelled = () => {
  state.showCancelled = !state.showCancelled;
};

const computedTableConfigHeaders = computed(() => {

  return props.tableConfig.headers;
});

const attachedItemSelected = (e, attachedItem) => {
  contextMenu.value.toggle(
    e,
    attachedItem,
    {
      ...props.contextData,
      rowItem: e.data,
      item: e.data,
    },
    e.data.Number
  );
};

defineExpose({
  computeItems,
  table,
});

onMounted(() => {
  // console.log("onMounted");
  computeItems();
});
</script>
