import {
    addOccurrence,
    closeOccurrence,
    editOccurrence
} from "./actions";

import ItemListView from "../../../components/ItemListView/ItemListView.vue"
import ItemView from "../../../components/ItemView/ItemView.vue"
import { addMOR } from "../MOR/actions";
import jobCardsAttached from "../JobCards/attachable";
import standardMenuActions from "../../standardMenuActions";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Title",
    "key": "Title"
}, {
    "label": "Aircraft",
    compute({ pageItem, store }) {

        if (!pageItem.Aircraft) return "No Aircraft Found";

        const acKeys = Object.keys(pageItem.Aircraft);
        return acKeys.map(key => {
            return store.state["Database/AircraftCodes"][key].Name
        });
    }
}, {
    "label": "MOR",
    "key": "MOR",
    "link": "Database/MORs/:MORID"
}, {
    "label": "Status",
    compute({ pageItem }) {
        return pageItem.Open === true ? "Open" : "Closed";
    }
}];

const menuActions = [
    {
        "label": "Edit Occurrence",
        "action": editOccurrence
    },
    {
        "label": "Add MOR",
        "action": addMOR,
        show({ pageItem }) {
            return [!pageItem.MOR, "A MOR has already been created for this occurrence"]
        }
    },
    {
        "label": "Close Occurrence",
        "action": closeOccurrence,
        show({ pageItem }) {
            return [pageItem.Open, "This occurrence is already closed"]
        }
    },
    standardMenuActions.back
];

export default [{
    "path": "/Database/Occurrences",
    "component": { ...ItemListView },
    "name": "Occurrences",
    "meta": {
        "app": "Database",
        "name": "Occurrences",
        "sideNav": true,
        "sideNavSection": "Occurrences",
        "appSwitcher": false,
        "icon": "database",
        "tableSettings": "Occurrences",
        "dataPath": "Database/Occurrences",
        "selectionRoute": "Database/Occurrences/{ID}",
        "storeData": ["Database/AircraftCodes"],
        "additionalTableHeaders": [{
            "headerName": "Status",
            "field": "Open",
            "colId": "Status",
            "flex": 1,
            "hide": false,
            "pivot": false,
            "rowGroup": false,
            "type": "text",
            "useValueFormatterForExport": true,
            "width": 310,
            valueFormatter(params) {
                return params.value === true ? "Open" : "Closed";
            }
        }],
        "menuActions": [{
            "label": "Add Occurrence",
            "action": addOccurrence
        }]
    }
}, {
    "path": "/Database/Occurrences/:id",
    "component": ItemView,
    "name": "Occurrence",
    "meta": {
        "app": "Database",
        "name": "Occurrences",
        "dataPath": "Database/Occurrences",
        "storeData": ["Database/AircraftCodes"],
        "selectionRoute": "Database/Occurrences/{ID}",
        title(item) {
            return `Occurrence: ${item.Number}`
        },
        "attachedItems": {
            "Attachments": {
                "attachables": [jobCardsAttached]
            }
        },
        "dataFields": dataFields,
        "menuActions": menuActions,
        "formMetadata": {
            "ParentType": "Occurrences"
        }
    }
}];
