import {
    faArrowLeft,
    faArrowsRotate,
    faArrowsUpToLine,
    faBars,
    faBook,
    faCalendar,
    faChartBar,
    faCheck,
    faCheckCircle,
    faDatabase,
    faDownload,
    faEllipsisV,
    faExclamationCircle,
    faEye,
    faFileContract,
    faHelmetSafety,
    faHome,
    faInfoCircle,
    faLock,
    faPen,
    faPhone,
    faPlane,
    faPlaneDeparture,
    faPoundSign,
    faSearch,
    faSignOutAlt,
    faStopwatch,
    faSyncAlt,
    faTable,
    faTimes,
    faTrash,
    faUser,
    faUsers,
    faUsersCog
} from "@fortawesome/free-solid-svg-icons";

import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
import {
    library
} from "@fortawesome/fontawesome-svg-core";

library.add(
    faPhone,    faArrowLeft,        
    faEye,
    faBars,
    faHome,
    faDatabase,
    faCheck,
    faTimes,
    faChartBar,
    faTable,
    faPoundSign,
    faCalendar,
    faStopwatch,
    faUser,
    faSearch,
    faSyncAlt,
    faUsers,
    faPlaneDeparture,
    faUsersCog,
    faSignOutAlt,
    faEllipsisV,
    faFileContract,
    faLock,
    faExclamationCircle,
    faArrowsUpToLine,
    faPlane,    faBook,
    faCheckCircle,    faTrash,
    faDownload,
    faArrowsRotate,
    faInfoCircle,
    faHelmetSafety,
    faPen
);

export default FontAwesomeIcon;
