export default [{
    "label": "Part Number",
    "name": "PartNumberID",
    "type": "dropdown",
    "dataLocation": "Database/PartNumbers",
    "idKey": "ID",
    "labelKey": "Number",
    "formStep": 1,
    "link": "Database/PartNumbers/:PartNumberID",
    "formStepName": "EQUIPMENT DETAILS"
}, {
    "label": "Technical Standard Order (UKTSO / ETSO / TSO)",
    "name": "TSO",
    "type": "text",
    "formStep": 1,
    "formStepName": "EQUIPMENT DESIGN STANDARD"
}, {
    "label": "Military Specification (MS)",
    "name": "MilitarySpecification",
    "type": "text",
    "formStep": 1
}, {
    "label": "British Standard (BSi)",
    "name": "BritishStandard",
    "type": "text",
    "formStep": 1
}, {
    "label": "European Standard (EN)",
    "name": "EuropeanStandard",
    "type": "text",
    "formStep": 1
}, {
    "label": "International Organization for Standardization (ISO)",
    "name": "InternationalStandard",
    "type": "text",
    "formStep": 1
}, {
    "label": "Underwriters Laboratories (UL)",
    "name": "UnderwritersLaboratories",
    "type": "text",
    "formStep": 1
}, {
    "label": "Other Standards",
    "name": "OtherStandards",
    "type": "text",
    "formStep": 1
}, {
    "label": "Electrical Specification",
    "name": "ElectricalSpecification",
    "type": "text",
    "formStep": 2,
    "formStepName": "EQUIPMENT SPECIFICATIONS"
}, {
    "label": "Mechanical Specification",
    "name": "MechanicalSpecification",
    "type": "text",
    "formStep": 2
}, {
    "label": "Installation Manual",
    "name": "InstallationManual",
    "type": "text",
    "formStep": 2
}, {
    "label": "DDP Reference (for equipment identified in TSO above)",
    "name": "DDPReference",
    "type": "text",
    "formStep": 2
}, {
    "label": "Test Reports in Lieu of DDP",
    "name": "TestReports",
    "type": "text",
    "formStep": 2
}, {
    "label": "Other Documents in Lieu of Test Reports",
    "name": "OtherDocuments",
    "type": "text",
    "formStep": 2
}, {
    "label": "Other Documents or Drawings",
    "name": "OtherDocumentsDrawings",
    "type": "text",
    "formStep": 2
}, {
    "label": "Does the equipment contain software?",
    "name": "ContainsSoftware",
    "type": "text",
    "formStep": 3,
    "formStepName": "SOFTWARE"
}, {
    "label": "Is the equipment classed as Avionics Equipment? (Software)",
    "name": "SoftwareAvionicsEquipment",
    "type": "text",
    "formStep": 3
}, {
    "label": "Does the equipment have a TSO? (Software)",
    "name": "SoftwareTSO",
    "type": "text",
    "formStep": 3
}, {
    "label": "Where there is a TSO does this identify software?",
    "name": "TSOIdentifySoftware",
    "type": "text",
    "formStep": 3
}, {
    "label": "Provide details of the development standard of the software",
    "name": "SoftwareDevelopmentStandard",
    "type": "text",
    "formStep": 3
}, {
    "label": "What is the Development Assurance Level (DAL) of the software?",
    "name": "SoftwareDAL",
    "type": "text",
    "formStep": 3
}, {
    "label": "Record the Software Accomplishment Summary Ref",
    "name": "SoftwareAccomplishmentRef",
    "type": "text",
    "formStep": 3
}, {
    "label": "Record any OPRs (Software)",
    "name": "SoftwareOPRs",
    "type": "text",
    "formStep": 3
}, {
    "label": "Does the equipment contain AEH (Airborne Electronic Hardware)?",
    "name": "ContainsAEH",
    "type": "text",
    "formStep": 4,
    "formStepName": "AIRBORNE ELECTRONIC HARDWARE"
}, {
    "label": "Is the equipment classed as Avionics Equipment? (AEH)",
    "name": "AEHAvionicsEquipment",
    "type": "text",
    "formStep": 4
}, {
    "label": "Is the Equipment classified as Simple or Complex Electronic Hardware?",
    "name": "AEHClassification",
    "type": "text",
    "formStep": 4
}, {
    "label": "Provide Justification of classification.",
    "name": "AEHClassificationJustification",
    "type": "text",
    "formStep": 4
}, {
    "label": "Does the equipment have a TSO? (AEH)",
    "name": "AEHTSO",
    "type": "text",
    "formStep": 4
}, {
    "label": "Where there is a TSO does this identify CEH?",
    "name": "TSOIdentifyCEH",
    "type": "text",
    "formStep": 4
}, {
    "label": "Provide details of the development standard of the CEH",
    "name": "CEHDevelopmentStandard",
    "type": "text",
    "formStep": 4
}, {
    "label": "What is the Development Assurance Level (DAL) of the CEH?",
    "name": "CEHDAL",
    "type": "text",
    "formStep": 4
}, {
    "label": "Record the CEH Accomplishment Summary Ref",
    "name": "CEHAccomplishmentRef",
    "type": "text",
    "formStep": 4
}, {
    "label": "Record any OPRs (CEH)",
    "name": "CEHOPRs",
    "type": "text",
    "formStep": 4
}];

