<template>
    <div class="py-1 px-2 my-1 rounded bg-gradient-to-br from-gray-100 to-gray-150 shadow-inner text-left m-2">
        <div class="my-2" v-for="table in tables" :key="table.title">
            <table class="border-collapse border border-black">
                <thead>
                    <tr class="">
                        <th style="font-size: 14px;" class="font-bold">{{ table.title }}, Amendment {{ table.amendment }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border border-black">
                        <td class="italic" style="padding: 1rem; font-size: 14px" v-html="table.table"></td>
                    </tr>
                    <tr class=" p-2">
                        <td style="margin: 4px; font-size: 14px">Notes here... </td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>
    </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { get } from "lodash";

const store = useStore();

const props = defineProps({
    contextData: Object,
});

// const regs = computed(() => props.contextData.item?.Regulations);

const tables = computed(() => {

    const amendmentNumber = props.contextData.item?.Regulations?.Amendment;

    return props.contextData.item?.Regulations?.Regs.map(key => {
        console.log(key);
        const reg = get(store.state["Database/Regulations/CS25"], key, null);

        if (!reg || !reg.amendments) return {
            title: "No regulations found",
            table: null,
            amendment: amendmentNumber
        };

        console.log(reg);

        const closestAmendment = Object.values(reg.amendments).map(a => {
            return {
                ...a,
                "amendment": parseInt(a.amendment)
            }
        }).sort((a, b) => a.amendment - b.amendment).filter(i => {
            return i.amendment <= amendmentNumber;
        }).pop();

        let {
            text
        } = get(reg.amendments, closestAmendment.ID, { text: "No regulations found" });

        return {
            title: reg.title,
            table: text,
            amendment: amendmentNumber
        };
    });
});


</script>
