import { cancelDesignChange, editDesignChange, hideDesignChange, unhideDesignChange } from "./actions";
import { stcs as stcAttachable, stcVals as stcValsAttachable } from "../STCs/attachable.js";
import { thirdPartyDocs, thirdPartyDraws, thirdPartyOther } from "../Miscellaneous/attachable.js";

import ItemListView from "../../../components/ItemListView/ItemListView.vue";
import ItemView from "../../../components/ItemView/ItemView.vue";
import TopCompWarning from "./TopCompWarning.vue";
import arrAttachable from "../Arrangements/attachable.js";
import commercialsAttachable from "../Commercials/attachable.js";
import concessionAttachable from "../Concessions/attachable.js";
import { createBoxRequest } from "../BoxRequests/actions.js";
import { designChangeItemsReport } from "./reports";
import documentsAttachable from "../Documents/attachable.js";
import doiAttachable from "../DOIs/attachable.js";
import dqnsAttachable from "../DQNs/attachable.js";
import drawingAttachable from "../Drawings/attachable.js";
import effectivitiesAttachable from "../Effectivities/attachable.js";
import jobCardsAttachable from "../JobCards/attachable.js";
import saddAttachable from "../SADDs/attachable.js";
import sbAttachable from "../ServiceBulletins/attachable.js";
import sowAttachable from "../SOWs/attachable.js";

const dataFields = [{
    "label": "Number",
    "key": "Number"
}, {
    "label": "Primary Commercial / Job Number",
    "key": "Parent",
    "link": "Database/Commercials/:ParentID"
}, {
    "label": "Responsible Engineer",
    "key": "ResponsibleEngineer"
}, {
    "label": "Customer",
    "key": "Customer"
}, {
    "label": "STC Approval Number",
    "key": "EASANumber"
}, {
    "label": "Mod Type",
    "key": "Type"
}, {
    "label": "Date Created",
    compute({ item }) {
        let d = new Date(item?.Date?.Created);
        return d.toLocaleDateString("en-GB", {
            "year": 'numeric', "month": 'long', "day": 'numeric'
        });
    }
}, {
    "label": "Title",
    "key": "Title",
    "class": "md:col-span-3"
}];

export default [{
    "path": "/Database/DesignChanges",
    "component": { ...ItemListView },
    "name": "Design Changes",
    "meta": {
        "app": "Database",
        "name": "Design Changes",
        "sideNavSection": "Designs",
        "sideNav": true,
        "appSwitcher": true,
        "appSwitcherPosition": 1,
        "security": "Database.Read",
        "icon": "database",
        "tableSettings": "DesignChanges",
        "dataPath": "Database/Refs/DesignChanges",
        "selectionRoute": "Database/DesignChanges/{ID}",
        "storeData": ["Database/DocumentTypes"],
    }
}, {
    "path": "/Database/DesignChanges/:id",
    "component": ItemView,
    "name": "Design Change",
    "meta": {
        "app": "Database",
        "name": "Design Changes",
        "dataPath": "Database/DesignChanges",
        "selectionRoute": "Database/DesignChanges/{ID}",
        "storeData": ["Database/DocumentTypes"],
        title(item) {
            return `Design Changes: ${item.Number}`;
        },
        "topComponent": TopCompWarning,
        "topComponentEvent": () => {
            console.log("Top Component Event");
        },
        "menuActions": [
            {
                "label": "Download Design Change Report",
                "action": designChangeItemsReport
            },
            {
                "label": "Edit Design Change",
                "action": editDesignChange,
                "break": true
            },
            {
                "label": "Cancel Design Change",
                "class": "text-red hover:bg-red",
                "color": "red",
                "action": cancelDesignChange,
                "break": true
            },
            {
                "label": "Create Box Request",
                "action": createBoxRequest,
                "break": true
            },
            {
                "label": "Hide Design Change",
                "break": true,
                "action": hideDesignChange,
                show({ pageItem }) {
                    return [!pageItem.Hidden, ''];
                }
            },
            {
                "label": "Unhide Design Change",
                "action": unhideDesignChange,
                show({ pageItem }) {
                    return [pageItem.Hidden, ''];
                }
            },
            {
                "label": "View in Archive",
                "break": true,
                action({ pageItem }) {
                    window.open(`https://stc21.sharepoint.com/sites/archive/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2Farchive%2FShared%20Documents%2FDesign Changes/${pageItem.ATAChapter}/S21_${pageItem.Number} ${pageItem.Title}`);
                }
            }
        ],
        "attachedItems": {
            "Design Items": {
                "attachables": [documentsAttachable, drawingAttachable, saddAttachable, effectivitiesAttachable]
            },
            "Design Queries": {
                "attachables": [concessionAttachable, dqnsAttachable, sbAttachable, doiAttachable]
            },
            "Project Items": {
                "attachables": [
                    commercialsAttachable,
                    jobCardsAttachable,
                    sowAttachable,
                    stcAttachable,
                    stcValsAttachable,
                    arrAttachable,
                    thirdPartyDocs,
                    thirdPartyDraws,
                    thirdPartyOther
                ]
            }
        },
        "dataFields": dataFields,
        "formMetadata": {
            "ParentType": "DesignChanges"
        }
    }
}];
